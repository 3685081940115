import React, { useEffect, useState } from 'react';
import './Question.scss';
import FlatPicker from "../userInput/flat-picker/FlatPicker";
import PercPicker from "../userInput/perc-picker/PercPicker";
import Input from "../userInput/input/Input";
import HeightPicker from "../userInput/height-picker/HeightPicker";
import AutoScroll from "../../shared/components/AutoScroll";
import AssessmentDatePicker from '../userInput/datePicker/AssessmentDatePicker';
import PhonePicker from '../userInput/phonePicker/PhonePicker';



//     dobPicker = 'dob-picker',
//     multiChoice = 'multi-choice',
//     textField = 'text-field',
//     heightPicker = 'height-picker',
//     weightPicker = 'weight-picker',
//     percPicker = 'perc-picker',
//     yesNo = 'yes-no',
//     multiChoiceMultiSelect = 'multi-choice-multi-select'

function NewQuestion({ question, onResponse, onRollback }) {
  const ref = React.useRef();
  const [shouldScroll, setShouldScroll] = useState(false);
  const [answerIds, setAnswerIds] = useState(question.answerIds);
  const [highestScoreOption, setHighestScoreOption] = useState(0);

  const checkAnswerIds = () => {
    setAnswerIds(question.answerIds)
    // console.log("answer ids changed");
  }

  useEffect(checkAnswerIds, [question.answerIds])

  useEffect(() => {
    setShouldScroll(true);
  }, [])

  function renderOptions() {
    let options = question.answers.map((answer) => {
      return {
        id: answer.promisId || answer.id,
        text: question.detail === "ISPROMIS" ? answer.text : answer.answerTRs[0].text,
        additional_info: answer.answerTRs?.[0].additional_info,
        target: answer.target,
        red_flag: answer.red_flag,
        score: answer.score
      }
    });

    switch (question.type) {
      case 'multi-choice':
        return <FlatPicker
          question={question}
          answerIds={answerIds}
          options={options}
          onOptionPress={(res) => {
            question.answerIds = [...question.answerIds, res.answer.promisId ? res.answer.promisId : res.answer.id]
            onResponse(res);
          }}
          onCancelPress={() => {

          }}
          onRollbackPress={(res) => {
            onRollback(res)
          }} />
      case 'height-picker':
        return <HeightPicker
          question={question}
          onConfirmPress={(res) => {
            onResponse(res);
          }}
          onRollbackPress={(res) => {
            onRollback(res)
          }}
          //TODO add another field for the placeholder
          placeholder={question.placeholder || ""}
        />
      case 'weight-picker':
        return <Input
          type="number"
          question={question}
          onConfirmPress={(res) => {
            onResponse(res);
          }}
          onRollbackPress={(res) => {
            onRollback(res)
          }}
          //TODO add another field for the placeholder
          placeholder={question.placeholder || ""}
        ></Input>
      case 'text-field':
      case 'insurance':
      case 'email':
      case 'first_name':
      case 'last_name':
        return <Input
          question={question}
          onConfirmPress={(res) => {
            onResponse(res);
          }}
          onRollbackPress={(res) => {
            onRollback(res)
          }}
          //TODO add another field for the placeholder
          placeholder={question.placeholder || ""}
        ></Input>
      case 'phone':
        return <PhonePicker
          question={question}
          onConfirmPress={(res) => {
            onResponse(res);
          }}
          onRollbackPress={(res) => {
            onRollback(res)
          }}
          //TODO add another field for the placeholder
          placeholder={question.placeholder || ""}
        ></PhonePicker>
      case 'perc-picker':
        return <PercPicker
          question={question}
          onConfirmPress={(res) => {
            onResponse(res);
          }}
          onRollbackPress={(res) => {
            onRollback(res)
          }}
          //TODO add another field for the placeholder
          placeholder={question.placeholder || ""}
        ></PercPicker>
      case 'dob-picker':
        return <AssessmentDatePicker
          question={question}
          onConfirmPress={(res) => {
            //TODO do this for numeric questions too
            // question.score_range = '55,,,10000000';
            if (question.score_range && !question.score_type) {
              var ageDifMs = Date.now() - new Date(res.res.value);
              var ageDate = new Date(ageDifMs); // miliseconds from epoch
              let years = Math.abs(ageDate.getUTCFullYear() - 1970);
              let parts = question.score_range.split(',');
              let savedP = 0;
              parts.some((p, i) => {
                if (years < (p || savedP)) {
                  res.score = i;
                  return true;
                } else {
                  savedP = (p || savedP);
                }
              })
            }
            onResponse(res);
          }}
          onRollbackPress={(res) => {
            onRollback(res)
          }}
          placeholder={question.placeholder || ""}
        ></AssessmentDatePicker>
      case 'multi-choice-multi-select':
        return <FlatPicker
          question={question}
          answerIds={answerIds}
          options={options}
          onOptionPress={(res) => {
            question.answerIds = [...question.answerIds, res.answer.promisId ? res.answer.promisId : res.answer.id];
            if (res.answer.score > highestScoreOption) {
              setHighestScoreOption(res.answer.score);
            }
            checkAnswerIds();
          }}
          onCancelPress={() => {

          }}
          onConfirmPress={(res) => {
            let scoreType = parseInt(question.score_type);
            if (scoreType) {
              res.score = Math.min(scoreType, question.answerIds.length);
            } else {
              res.highestScoreOption = highestScoreOption;
            }
            onResponse(res);
          }}
          confirmText={question.input_type || "Continue"}
          onRollbackPress={(res) => {
            onRollback(res)
          }} />
      default:
        return null;
    }
  }
  //ref={ref}
  return (
    <>
      {shouldScroll && <AutoScroll
        delay={300}
        mounted={() => setShouldScroll(false)}
      />}
      {renderOptions()}
    </>
  );
}
export default NewQuestion