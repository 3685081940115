import { Row, Col } from "react-bootstrap";
import logo from "../../../../assets/limber-logo.png";

const Header = () => {
  return (
    <Row>
      <Col md={12} className="pt-4 pb-4">
        <img src={logo} alt={"Limber"} className="logo" />
      </Col>
    </Row>
  );
};

export default Header;
