import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NewInput from "../../NewUI/Input";
import "./Input.scss";

function Input({ question, onConfirmPress, placeholder = "", confirmText = "Confirm", active }) {
  const [value, setValue] = useState(question.value || "");
  const [invalid, setInvalid] = useState(false);
  const [confirmed, setConfirmed] = useState(value !== "");

  useEffect(() => {
    let newValue = question.value || "";
    setValue(newValue);
    if (!newValue) {
      setConfirmed(false);
    }
  }, [question.value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const confirmPress = () => {
    setInvalid(false);
    if (!value /*|| value.length < 3*/) {
      setInvalid(true);
      return;
    }
    if (question.regex) {
      let re = new RegExp(question.regex);
      if (question.type === "email") {
        re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      }
      if (!re.test(value)) {
        // return Swal.fire({
        //   title: question.validationText,
        //   customClass: {
        //     confirmButton: "btn-main-limber",
        //     cancelButton: "btn-green-limber",
        //   },
        //   confirmButtonText: "Back",
        // });
        return setInvalid(true);
      }
    }
    setConfirmed(true);
    onConfirmPress({
      question,
      res: {
        value,
      },
    });
  };

  const type = question.input_type ? question.input_type : "text";

  //max length default is 524288
  return (
    <div className={"textField"}>
      <div className={""}>
        {question.maxLength && (
          <p className="helperLabel">Please enter {question.maxLength} characters (only numbers)</p>
        )}
        <input
          type={type}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          readOnly={confirmed}
          className={active && "active"}
          autoFocus
          style={{borderColor: invalid ? 'red' : 'inherit'}}
        />
        <div class="invalid-feedback" style={{display: invalid ? 'block' : 'none'}}>
          {question.validationText || "Invalid Input"}
        </div>
      </div>
      {!confirmed && (
        <div className="d-flex mt-3">
          <div className={"continue btn-blue " + (!!value ? "selectable" : "")} onClick={confirmPress}>
            <span>{confirmText ? confirmText : ""}</span>
          </div>
        </div>
      )}
    </div>
  );
}
export default Input;
