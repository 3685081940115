import React from "react";
import "./styles/main.scss";
import { ProfileProvider } from "./navigation/ProfileContext";
import Routes from "./navigation/Routes";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NewAssessment from "./screens/sections/NewAssessment";
import Main from "./components/NewUI/Main";
import SplashScreen from "./components/NewUI/SplashScreen";
import Landing from "./screens/Landing";

function App() {
  return (
    <ProfileProvider>
      <Router>
        <Route path="/landing">
          <Landing />
        </Route>

        <Route exact path="/">
          <NewAssessment />
        </Route>
      </Router>
      {/* <Routes/> */}
      {/*

      TODO: add a show=false to hidden this splash
      TODO: connect the continue button to new assesment

       <SplashScreen splash />*/}
      {/* <Main/> */}
    </ProfileProvider>
  );
}

export default App;
