import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "./HeightPicker.scss";

function Input({ question, onConfirmPress, placeholder = "", confirmText = "Confirm", active }) {
  // const [valFeets = "", valInches = ""] = question.value?.split("-") || [];
  let valFeets = Math.floor((question.value || 0) / 12);
  let valInches = ((question.value || 0) - (valFeets * 12));
  const [valueInches, setValueInches] = useState(valFeets || "");
  const [valueFeets, setValueFeets] = useState(valInches || (valFeets ? "0" : ""));
  const [confirmed, setConfirmed] = useState(valueInches !== "" && valueFeets !== "");

  let [placeholderFeets = "", placeholderInches = ""] = placeholder?.split("-") || [];

  useEffect(() => {
    let newValueFeets = Math.floor((question.value || 0) / 12);
    let newValueInches = ((question.value || 0) - (newValueFeets * 12));
    setValueFeets(newValueFeets || "");
    newValueInches = newValueInches || (newValueFeets ? "0" : "")
    setValueInches(newValueInches);
    if (!newValueFeets || !newValueInches) {
      setConfirmed(false);
    }
  }, [question.value]);

  const handleChangeFeets = (event) => {
    console.log(event);
    setValueFeets(event.target.value);
  };

  const handleChangeInches = (event) => {
    console.log(event);
    setValueInches(event.target.value);
  };

  const confirmPress = () => {
    if (!valueInches) return;
    if (!valueFeets) return;
    setConfirmed(true);
    onConfirmPress({
      question,
      res: {
        value: parseInt(valueFeets) * 12 + parseInt(valueInches),
      },
    });
  };

  const ftValues = {
    0: "0 ft",
    1: "1 ft",
    2: "2 ft",
    3: "3 ft",
    4: "4 ft",
    5: "5 ft",
    6: "6 ft",
    7: "7 ft",
  }
  const inValues = {
    0: "0 in",
    1: "1 in",
    2: "2 in",
    3: "3 in",
    4: "4 in",
    5: "5 in",
    6: "6 in",
    7: "7 in",
    8: "8 in",
    9: "9 in",
    10: "10 in",
    11: "11 in",
  }

  console.log(valFeets)
  console.log(valInches)
  //max length default is 524288
  return (
    <div className={"textField"}>
      <div className={""}>
        {/* <input
          type="number"
          value={valueFeets}
          onChange={handleChangeFeets}
          placeholder={"Feet"}
          readOnly={confirmed}
          className={active && "active"}
          autoFocus
        /> */}
        <select name="select" onChange={handleChangeFeets}>
          <option value={0} hidden selected>Feet</option>
          {Object.keys(ftValues).map(k => {
            return <option
              value={Number(k)}
              readOnly={confirmed}              
              className={active && "active"}
              autoFocus
            >
              {ftValues[k]}
            </option>
          })}
        </select>
        <select name="select" onChange={handleChangeInches}>
          <option value={0} hidden selected>Inches</option>
          {Object.keys(inValues).map(i => {
            return <option
              value={Number(i)}
              readOnly={confirmed}
              className={active && "active"}
              autoFocus
            >
              {inValues[i]}
            </option>
          })}
        </select>
        {/* 
        <input
          type="number"
          value={valueInches}
          onChange={handleChangeInches}
          placeholder={"Inches"}
          readOnly={confirmed}
          className={active && "active"}
        /> */}
      </div>
      {!confirmed && (
        <div className="d-flex mt-3">
          <div className={"continue btn-blue " + (valueInches && valueFeets ? "selectable" : "")} onClick={confirmPress}>
            <span>{confirmText ? confirmText : ""}</span>
          </div>
        </div>
      )}
    </div>
  );
}
export default Input;
