import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Row, Col, Button } from "react-bootstrap";
import logo from "../../assets/limber-logo.png";


function Landing() {
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);

  return (
    <Container fluid className="splashScreen">
      <Container>
        <Row className="vh-100 ">
          <Col md={12} className="d-flex justify-content-center align-content-center align-items-center flex-column">
            {/* <img src={logo} alt="Limber" className="logo" /> */}

            <div className="pb-5 text-center">
              <div style={{ textAlign: "center", margin: "auto" }}>
                <div className={"btn btn-blue btn-blue-lg selectable"} onClick={() => {
                  urlSearchParams.append("comesFrom", "landing")
                  history.push({ pathname: '/', search: urlSearchParams.toString() });
                  history.go();
                }}>
                  <span> New Patient</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Landing