import { Row, Col } from "react-bootstrap";
import { FaUndo } from "react-icons/fa";

const UiQuestion = ({ label, onRollback, question, children }) => {
  let showRollback = true;

  if (question.type === "multi-choice-multi-select" && !question.answers.length) {
    showRollback = false;
  }

  return (
    <Row>
      <Col md={12} className="questioUI">
        <div className="pb-2">
          <label>{label}</label>
        </div>

        <div className="d-flex justify-content-start w-100">
          <div className="pe-3 pt-1">
            {
              showRollback && <FaUndo
                size={20}
                className="undoButton"
                onClick={() => {
                  onRollback(question);
                }}
              />
            }
          </div>
          {/* <div className="w-100"> */}
          <div className="">
            {children}
            {/* here comes the input */}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default UiQuestion;
