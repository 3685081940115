import axios from "axios";

// const urlBase= process.env.REACT_APP_API_BASE_URL2

// const urlBase = "https://dev-api.limberhealth.com/v2"

// const urlBase = "http://127.0.0.1:3000"

// const urlBase = "https://mlcajf5031.execute-api.us-east-1.amazonaws.com/develop"

// const urlBase = "https://x43ypt0y8c.execute-api.us-east-1.amazonaws.com/develop"

const urlBase = "https://api.v2.limberhealth.com"

export const getQuestion = (questionId) => {
    return new Promise(async (resolve, reject) => {
        let url = `${urlBase}/questions/${questionId}`;
        // let url = `http://127.0.0.1:3000/questions/${questionId}`;
        // if(questionId) {
        //     url+= `/${questionId}`;
        // }
        axios.get(url)
            .then(res => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            }
            )
    });
}

export const getFirstQuestion = (assessmentId) => {
    return new Promise(async (resolve, reject) => {
        let url = `${urlBase}/questions/actions/getFirst?assessment_id=${assessmentId}`;
        // let url = `http://127.0.0.1:3000/questions/${questionId}`;
        // if(questionId) {
        //     url+= `/${questionId}`;
        // }
        axios.get(url)
            .then(res => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            }
            )
    });
}

export const getSections = () => {
    return new Promise(async (resolve, reject) => {
        let url = `${urlBase}/assessmentSections`;
        // let url = `http://127.0.0.1:3000/assessmentSections`;
        axios.get(url)
            .then(res => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            }
            )
    });
}


export const saveSurvey = (data) => {
    return new Promise(async (resolve, reject) => {

        // TODO: Change this to prevent none parametrized url parameter to store surveys into hopkins
        let uri = `${process.env.REACT_APP_API_BASE_URL}/saveSurveyHopkins`;
        // if(paramAssessmentOrg === 'limber'){
        //     uri = `${process.env.REACT_APP_API_BASE_URL}/saveSurveyLimber`
        // }
        axios.post(uri, data)
            .then(res => {
                resolve(res.data);
            }).catch((err) => {
                console.log(err)
                reject(err)
            })
    });
}

export const saveGetNextQuestion = (data) => {
    return new Promise(async (resolve, reject) => {
        // let uri = "https://dev-api.limberhealth.com/v2/questions/actions/saveGetNext";
        let uri = `${urlBase}/qcdr/temp/create`;
        // if(paramAssessmentOrg === 'limber'){
        //     uri = `${process.env.REACT_APP_API_BASE_URL}/saveSurveyLimber`
        // }
        axios.post(uri, data)
            .then(res => {
                resolve(res.data);
            }).catch((err) => {
                console.log(err)
                reject(err)
            })
    });
}

export const saveResult = (data) => {
  //TODO remove not being used
    return new Promise(async (resolve, reject) => {
        let uri = `${urlBase}/results`;
        axios.post(uri, data)
            .then(res => {
                resolve(res.data);
            }).catch((err) => {
                console.log(err)
                reject(err)
            })
    });
}

export const rollbackQuestions = (data) => {
    return new Promise(async (resolve, reject) => {
        let uri = `${urlBase}/qcdr/temp/rollback`;
        axios.post(uri, data)
            .then(res => {
                resolve(res.data);
            }).catch((err) => {
                console.log(err)
                reject(err)
            })
    });
}

export const savePromisScores = (promisType, questionIds, answerIds, score, clientId, assessment_id, qrCode, targetAfterPromis, assessmentType, orgId) => {
    return new Promise(async (resolve, reject) => {
        let uri = `${urlBase}/qcdr/temp/create`;
        // let uri = `${urlBase}/results/actions/savePromisScore`;
        let type;

        if (promisType === "pain") {
            type = "promis-pain";
        } else if (promisType === "tos-messages") {
            type = "tos-messages";
        } else {
            type = "promis-function";
        }

        // let resultToSave = {
        //     type,
        //     res: score,
        //     uuid: clientId,
        //     assessment_id,
        //     question_id: 0
        // };

        let resultToSave = {
            res: score,
            question_id: 0,
            type,
            uuid: clientId,
            assessment_id,
            question: {
                id: 0,
                target: targetAfterPromis,
                type,
                res: score
            },
            target: targetAfterPromis,
            code: qrCode,
            orgId,
        }

        axios.post(uri, resultToSave)
            .then(res => {
                resolve(res.data);
            }).catch((err) => {
                console.log(err)
                reject(err)
            })
    });
}

export const getFullAssessment = (assessmentId) => {
    return new Promise(async (resolve, reject) => {
        let url = `${urlBase}/assessments/${assessmentId}?includeQuestions=true`;
        axios.get(url)
            .then(res => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            }
            )
    });
}

export const getFullAssessmentById = (assessmentId) => {
    return new Promise(async (resolve, reject) => {
        let url = `${urlBase}/assessments/byid/${assessmentId}?includeQuestions=true`;
        axios.get(url)
            .then(res => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            }
            )
    });
}

export const getAssessmentByQrCode = (qrCode) => {
    return new Promise(async (resolve, reject) => {
        let url = `${urlBase}/assessments/getbycode?code=${qrCode}&includeQuestions=true`;
        axios.get(url)
            .then(res => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            }
            )
    });
}

export const saveScore = async (score, assessment_id, uuid, assessmentType, orgId) => {
    let uri = `${urlBase}/qcdr/temp/create`;
    // let uri = `${urlBase}/results/actions/savePromisScore`;

    // let resultToSave = {
    //     type,
    //     res: score,
    //     uuid: clientId,
    //     assessment_id,
    //     question_id: 0
    // };

    let category = "Low";

    if(score > 2) {
        category = "High"
    } else if (score > 1) {
        category = "Moderate"
    }

    let resultToSave = {
        res: category,
        question_id: 0,
        type: 'risk_category',
        uuid,
        assessment_id,
        question: {
            id: 0,
            target: 1,
            type: 'risk_category',
            res: category,
        },
        target: 1,
        orgId,
    }

    

    let resultToSave2 = {
        res: score,
        question_id: 0,
        type: 'risk_value',
        uuid,
        assessment_id,
        question: {
            id: 0,
            target: 1,
            type: 'risk_value',
            res: score,
        },
        target: 1,
        orgId,
    }

    await axios.post(uri, resultToSave)

    await axios.post(uri, resultToSave2)

    return true;
}
