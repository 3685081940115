import React, { useEffect, useState } from "react";
import "./PhonePicker.scss";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import Swal from "sweetalert2";

function range(startAt = 0, to = 0) {
  let size = to - startAt;
  return [...Array(size).keys()].map((i) => i + startAt);
}

const years = range(1900, new Date().getFullYear() + 1, 1);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function PhonePicker({
  question,
  onConfirmPress = () => { },
  onRollbackPress = () => { },
  confirmText = "Confirm",
  placeholder = "",
}) {
  const [value, setValue] = useState(question.value || undefined);
  const [confirmed, setConfirmed] = useState(value !== '');
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    let newValue = question.value || undefined;
    setValue(newValue);
    if (!newValue) {
      setConfirmed(false);
    }
  }, [question.value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const confirmPress = () => {
    setInvalid(false);
    if (value && isValidPhoneNumber(value)) {
      setConfirmed(true);
      onConfirmPress({
        question,
        res: {
          value,
        },
      });
    } else {
      setInvalid(true);
      // Swal.fire({
      //   title: "Invalid Phone Number",
      //   customClass: {
      //     confirmButton: "btn-main-limber",
      //     cancelButton: "btn-green-limber",
      //   },
      //   confirmButtonText: "Back",
      // });
    }
  };

  return (
    <div className={"textfield"}>
      <div className={"input"}>
        {/* <input
          // autoFocus
          type={"date"}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          readOnly={confirmed}
          className="datepicker-input mb-3"
        /> */}
        <PhoneInput
          defaultCountry="US"
          placeholder="Enter phone number"
          value={value}
          onChange={setValue}
          readOnly={confirmed}
          style={{borderColor: invalid ? 'red' : 'inherit'}}
          className={`phoneInput ${invalid ? 'invalidPhone' : ''}`}
        />
        <div class="invalid-feedback" style={{display: invalid ? 'block' : 'none'}}>
          {question.validationText || "Invalid Phone Number"}
        </div>
      </div>
      {!confirmed && (
        <div className={"btn continue btn-blue " + (!!value ? "selectable" : "")} onClick={confirmPress}>
          <span>{confirmText ? confirmText : ""}</span>
        </div>
      )}
    </div>
  );
}
export default PhonePicker;
