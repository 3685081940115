import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import logo from "../../assets/limber-logo.png";
import React, {useContext, useEffect, useState} from "react";
import Loader from "react-loader-spinner";
import {getProfile} from "../services/api";
import {ProfileContext} from "./ProfileContext";
import Assessment from "../screens/sections/Assessment";
import NewAssessment from "../screens/sections/NewAssessment";
import RetakeAssessment from "../screens/retakeAssessment";

const urlParams = new URLSearchParams(window.location.search);
const paramUserID = urlParams.get('id') || null;


export default function Routes() {

    const { profile, setProfile } = useContext(ProfileContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        //test without profile
        // setProfile({
        //     "id": 979,
        //     "name": "Patricio",
        //     "lastName": "Alvarez",
        //     "email": "patosmack+520@gmail.com",
        //     "program": "LBP",
        //     "programId": 1
        // })
        // setTimeout(() => {
        //     setLoading(false);
        // }, 300);
    }, [])

    return (
        <Router>
            <div className="App">
                <div className={'logo'}>
                    <img src={logo} alt={'Limber'}/>
                </div>

                {loading ? (
                    <div className={'centerContent mt-5'}>
                        <Loader
                            type="ThreeDots"
                            color="yellow"
                            height={30}
                            width={30}
                        />
                    </div>
                ) : (
                    <Switch>
                        <Route exact path="/retakeAssessment">
                            <RetakeAssessment/>
                        </Route>
                        <Route exact path="/">
                            <NewAssessment/>
                        </Route>
                    </Switch>
                )}

            </div>
        </Router>
    );
}
