import React, {useContext, useEffect, useState} from 'react';
import {ProfileContext} from "../../navigation/ProfileContext";
import {getSections, saveSurvey} from "../../services/api";
// import {getSections, saveSurvey} from "../../services/api2";
import {findByValue, objectExists, objectsAreEqual} from "../../../helpers/helpers";
import Loader from "react-loader-spinner";
import ProgressBar from "@ramonak/react-progress-bar";

import Title from "../../components/Title/Title";
import Button from "../../components/Button/Button";
import Promis from "../../components/Promis/Promis";


function Assessment() {

    const [completionSpeed, setCompletitionSpeed] = useState(0);
    const { profile, setProfile } = useContext(ProfileContext);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [sections, setSections] = useState([]);
    const [survey, setSurvey] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(1);
    const [questions, setQuestions] = useState([]);
    const [currentProgress, setCurrentProgress] = useState(0);
    const [isLastSection, setIsLastSection] = useState(false);

    React.useEffect(() => {
        setTimeout(() => setCompletitionSpeed(completionSpeed + 1), 1000);
    }, [completionSpeed]);

    useEffect(() => {
        setIsLastSection(false)
        if(profile){
            getSections().then((res) => {
                setLoading(false)
                // setSections(res)
                setSurvey(res)
            }).catch((err) => {
                setLoading(false)
            })
        }
    }, [profile])

    useEffect(() => {
        if(survey && survey.length > 0){
            let currentQuestion = findByValue(survey.questions, 'order', currentIndex);
            if(currentQuestion){
                if(!objectExists(questions, currentQuestion, true, ['answer', 'tmp_answer', 'answer_info'])){
                    setQuestions(state => {
                        return [...state, currentQuestion]
                    })
                }
                if(currentQuestion.autoSkip){
                    gotoSection(currentQuestion)
                }

                // if(currentQuestion.isLastSection){
                //     setIsLastSection(currentIndex)
                // }


            }
        }
        return () => {

        };
    }, [survey, currentIndex])

    function incrementProgress(){
        // console.log(currentIndex)
        if(isLastSection){
            setCurrentProgress(100)
        }else{
            let incremente = 10;
            if(currentProgress >= 90){
                incremente = 3;
            }
            let newProgress = currentProgress + incremente;
            setCurrentProgress(newProgress)
        }

    }

    function gotoSection(target){
        let onCompleteInfo  = target.onCompleteInfo;
        if(onCompleteInfo){
            let delay = onCompleteInfo.delay;
            let targetID = onCompleteInfo.target;
            let type = onCompleteInfo.type;
            if(target.isLastSection){
                setCurrentProgress(100)
                setIsLastSection(true)
            }
            if(type === 'goto-section'){
                setTimeout(() => {
                    setCurrentIndex(targetID);
                }, delay ? delay : 850);
            }
        }
    }


    function replaceQuestionValue(question, key, value){
        let newQuestions = [...questions];
        questions.map((data,index) => {
            if(objectsAreEqual(data, question, true, ['answers'])){
                if(!value){
                    delete data[key];
                }else{
                    data[key] = value;
                }
            }
            newQuestions[index] = data;
        });
        setQuestions(newQuestions);
    }

    function renderQuestion(item){
        if(item) {

            let component = item.component;
            if(component){
                let type = component.type;
                let props = component.props;

                switch (type) {
                    case 'Title':
                        return <Title key={`comp${item.id}`} {...component.props}/>
                    case 'Promis':
                        return <Promis
                            key={`comp${item.id}`} {...component.props}
                            onDone={(res) => {
                                replaceQuestionValue(item, 'answers', res)
                                gotoSection(item)
                            }}
                            onResponse={(answer) => {
                                incrementProgress()
                            }}
                            onRollback={(res) => {
                                if(currentIndex > item.id){
                                    let newQuestions = [];
                                    let indexFound = false;
                                    questions.map((data,index) => {
                                        if(objectsAreEqual(data, item, true, ['answers'])){
                                            indexFound = true
                                            newQuestions[index] = data;
                                        }
                                        if(!indexFound){
                                            newQuestions[index] = data;
                                        }
                                    });
                                    setQuestions(newQuestions);
                                    setCurrentIndex(item.id);
                                }
                            }}
                            />
                    case 'Submit':
                        return <Button key={`comp${item.id}`} title={'Submit Assessment'} onClick={() => {
                            let promisScore = [];
                            let responses = [];
                            questions.map((data ) => {
                                if(data.answers){
                                    if(data.answers.score && data.answers.mode){
                                        promisScore.push({
                                            score: data.answers.score,
                                            type: data.answers.mode,
                                        })
                                    }
                                    let answ = data.answers.response;
                                    if(Array.isArray(answ)){
                                        answ.map((datum) => {
                                            if(datum.answer){
                                                responses.push(datum.answer)
                                            }
                                        });
                                    }
                                }
                            }).filter(function( item ) {
                                return item !== undefined && item !== null;
                            });
                            let data = {
                                promisScores:promisScore,
                                userId: profile.id,
                                completedSections:responses,
                                programId: profile.programId,
                                completionSpeed: completionSpeed,
                            }
                            setSaving(true);
                            saveSurvey(data).then((res) => {
                                setSaving(false);
                                setSaved(true);
                            }).catch(() => {
                                setSaving(false);
                                setSaved(false);
                                alert('There was a problem saving your survey, please try again')
                            })

                        }}/>
                    default:
                        break;
                }
                return <></>

            }
        }
    }

    if(loading){
        return <div className={'centerColContent mt-5'}>
            <Loader
                type="ThreeDots"
                color="#0D6E76"
                height={30}
                width={30}
            />
        </div>
    }

    if(saving){
        return <div className={'centerColContent mt-5'}>
            <div style={{display: 'flex'}}>
            <Loader
                type="ThreeDots"
                color="#0D6E76"
                height={30}
                width={30}
            />
            </div>
            <p style={{textAlign:'center', marginTop:60}}>Saving Assessment</p>
        </div>
    }

    if(saved){
        return <div>
            <p style={{textAlign:'center', marginTop:60}}>Your Assessment was saved successfully, thanks {profile.name}</p>
        </div>
    }


    return (
        <div>
            <div className={'mt-5'}>
                {(!loading && questions && questions.length > 0) && questions.map(item => {
                    return renderQuestion(item);
                })}
            </div>
            {currentProgress > 0 && <ProgressBar completed={currentProgress} bgColor={'#303D50'} labelAlignment={'center'}  height={'45px'} /> }
        </div>

    );
}

export default Assessment;